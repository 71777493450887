// Variable overrides

// Global Constants
$navbar-height: 5.63rem;
$desktop-main-content-padding: 7rem;
$mobile-main-content-padding: 1.5rem;
$mobile-max-width: 768px;

// Default Colors
// TODO: A lot of these colors are unused/incorrect (from Template), but we will update/remove them as we build out components
$background-color: #ffffff;
$hover-green-color: #0d5d4b;
$light-blue-color: #4d5e72;
$dark-blue-color: #17293e;
$white-color: #ffffff;
$off-white-color: #f8f8f8;
$extra-light-grey-color: #e8e8e8;
$mid-grey-color: #a0a0a0;
$dark-grey-color: #4e4e4e;
$hover-gray-color: #dadbde;
$light-grey-color: #c9c9c9;
$extra-dark-gray-color: #737680;
$faint-grey: #eeeeee;
$light-red-color: #fa555533;
$red-color: #fa5555;
$negative-color: #e56659;
$negative-highlight: #fcebea;
$off-red-color: #984545;
$black-color: #000000;
$turquoise-color: #b1e7e2;
$ocean-color: #00504b;
$salmon-color: #f28872;
$active-color: #31bf5d;
$inactive-color: #737680;
$green-color: #63d58f;
$yellow-color: #ffd74b;
$yellow-color-80: #ffd74bcc;

// TODO: These colors are all correct, taken from Figma
$primary-green-color: #13856b;
$primary-blue-color: #03536b;
$primary-red-color: #dc3725;
$primary-yellow-color: #e09f2f;
$primary-beige-color: #e6d7b8;
$primary-neutral-color: #a5b0ae;
$secondary-neutral-color: #cacfd5;
$cool-neutral-300: #d2d5db;
$pure-grey-neutral: #afafaf;
$pure-grey-neutral-700: #3a3a3a;
$pure-grey-neutral-600: #666666;
$pure-grey-neutral-500: #929292;
$pure-grey-neutral-300: #cacaca;
$pure-grey-neutral-200: #dedede;
$pure-grey-neutral-100: #f2f2f2;
$pure-grey-neutral-50: #f7f7f7;
$pale-primary-green-color: #aac8c1;
$text-hint-primary-color: #929292;
$primary-neutral-50: #f6f8f7;
$primary-green-50: #e8f3f1;
$primary-green-600: #0d5d4b;
$light-green: #e6eef1;
$dark-green: #0a493b;
$pending-item-color: #d99025;
$border-primary-yellow: #d2811c;
$active-pill-green: #0d5d4b;
$red-500: #c63221;
$red-50: #fcebea;
$green-400: #13856b;
$green-40: #e8f3f1;
$orange-50: #fcf6eb;

$primary-color: $primary-green-color;
$secondary-color: $primary-blue-color;
$primary-text-color: $black-color;
$grey-text-color: $pure-grey-neutral;
$light-text-color: $white-color;
$transparent-color: transparent;

// Nav Items
$nav-background-color: $primary-color;
$nav-hover-color: $white-color;
$nav-active-text-color: $white-color;
$nav-active-background-color: $transparent-color;
$nav-inactive-text-color: $pale-primary-green-color;
$nav-notification-pill-color: $red-color;

// Button Items
$button-white-text-color: $white-color;
$button-black-text-color: $black-color;
$button-preferred-hover-color: $hover-green-color;
$button-neutral-color: $white-color;
$button-neutral-hover-color: $pure-grey-neutral-50;
$button-dark-neutral-color: $dark-grey-color;
$button-dark-neutral-hover-color: $extra-dark-gray-color;
$button-outlined-hover-color: $pure-grey-neutral;
$button-border-color: $hover-gray-color;
$button-text-color: $white-color;
$button-text-hover-color: $off-white-color;

// Label Items
$label-positive-color: $turquoise-color;
$label-positive-text-color: $ocean-color;
$label-neutral-color: $hover-gray-color;
$label-neutral-text-color: $black-color;
$label-negative-color: $salmon-color;
$label-negative-text-color: $white-color;

// Tabs
$material: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: $primary-color,
  600: #1e88e5,
  700: #1976d2,
  800: #1565c0,
  900: #0d47a1,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: $primary-text-color,
    100: $primary-text-color,
    200: $primary-text-color,
    300: $primary-text-color,
    400: $primary-text-color,
    500: $light-text-color,
    600: $light-text-color,
    700: $light-text-color,
    800: $light-text-color,
    900: $light-text-color,
    A100: $primary-text-color,
    A200: $light-text-color,
    A400: $light-text-color,
    A700: $light-text-color
  )
);

// Drop Down
$dropdown-base-color: $pure-grey-neutral;
$dropdown-hover-color: $white-color;
$dropdown-text-color: $black-color;
$dropdown-border-color: $black-color;
$dropdown-hover-color: $white-color;
$dropdown-hover-border-color: $black-color;
$dropdown-hover-text-color: $black-color;

// Pills
$pill-text-color: $black-color;
$pill-text-active-color: $white-color;
$pill-default-color: $pure-grey-neutral;
$pill-hover-color: $hover-gray-color;
$pill-active-color: $primary-blue-color;

// Checkboxes
$checkbox-base-color: $pure-grey-neutral;
$checkbox-hover-color: $primary-color;
$checkbox-checkmark-color: $white-color;
$checkbox-checked-color: $primary-color;

// Switches
$switch-base-color: $extra-light-grey-color;
$switch-hover-color: $hover-gray-color;
$switch-activated-color: $primary-color;

// Radio
$radio-base-color: $pure-grey-neutral;
$radio-hover-color: $hover-gray-color;
$radio-activated-color: $primary-color;

// Toggle
$toggle-base-color: $pure-grey-neutral;
$toggle-hover-color: $hover-gray-color;
$toggle-activated-color: $primary-color;

// Media Upload
$media-upload-border: $hover-gray-color;
$media-upload-background: $off-white-color;
$media-upload-blue-color: #388efc;
$media-upload-grey-color: $extra-dark-gray-color;
$media-name-color: #a2a2a2;
$media-size-color: #a2a2a2;
$media-error-color: $red-color;

// Lists
$list-background-color: #fdfdfd;
$list-border-color: #707070;
$list-hover-color: $off-white-color;
$list-active-color: #deecff;

// Cards
$card-base-color: #9fa2ad;
$card-banner-color: $pure-grey-neutral;
$card-hover-color: $hover-gray-color;
$card-title-color: $extra-dark-gray-color;
$card-text-color: $black-color;

// Loading
$progress-bar-background-color: $off-white-color;
$progress-bar-progress-color: $primary-blue-color;
$progress-bar-error-color: $red-color;
$progress-bar-success-color: $green-color;

// Textfield
$textfield-fill-color: $pure-grey-neutral;
$textfield-focus-border-color: $black-color;
$textfield-focus-fill-color: $white-color;
$textfield-error-red-color: $red-color;

// Datatable
$datatable-overdue-row-color: #fcebea;
$datatable-overdue-border-color: #ed968d;

// Scrollbar
$scrollbar-bg-color: $white-color;
$scrollbar-color: $light-blue-color;

// Variable overrides
$card-border-radius: 0.75rem;
$table-border-color: $faint-grey;

// Buttons (in the case of not using library buttons)
$button-default-text-color: #{$button-text-color};
$button-default-background-color: #{$black-color};
$button-default-hover-color: #{$button-text-hover-color};
$button-default-padding: 0.75rem 1rem;
$button-default-border-radius: 0.375rem;
$button-default-height: 2.75rem;
$button-default-font-size: 1rem;
$button-primary-text-color: #{$white-color};
$button-primary-background-color: #{$primary-green-color};
$button-primary-hover-color: #{$hover-green-color};
$button-primary-disabled-background-color: #{$button-neutral-color};
$button-font-family: 'Montserrat';
$button-font-weight: 600;
$button-font-style: normal;
